'use strict';

function fillModalElement(data, $targetModal){
    if ($targetModal && $targetModal.length > 0) {
        $targetModal.find('.modal-body').html(data);
    } else {
        $('.modal-body').html(data);
    }
    var miniCartResponse = {
        'quantityTotal':parseInt($('#mini-cart-quantity').val()),
        'minicartCountOfItems': parseInt($('#mini-cart-quantity').val()) + ' items in the cart'
    }
    $('.minicart').trigger('count:update', miniCartResponse);
    $('#cart-modal').modal('show');
    $('[data-toggle="popover"]').popover();
}

function getModalHtmlElement() {
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="cart-modal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog add-to-bag-dialog modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link d-none" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span class="close-icon"></span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - The ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if(response.error){
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    } else {
        getModalHtmlElement();
        var $targetModal = $('#cart-modal');
        fillModalElement(response, $targetModal);
    }
}

/**
 * Returns price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The price value
 */
function getPrice(priceModel) {
    return !priceModel.sales ? priceModel.list.value : priceModel.sales.value;
}

/**
 * Returns list price value
 * @param {models.price.default.DefaultPrice} priceModel - The price model
 * @returns {number} The list price value
 */
function getListPrice(priceModel) {
    return priceModel.list ? priceModel.list.value : '';
}

/**
 * Add an offer to the cart
 */
function addOfferToCart() {
    $(document).on('click', 'button.offer-add-to-cart', function () {
        var addToCartObject = $(this).data('mirakl')
            ? $(this).data('mirakl')
            : null;
        if (addToCartObject) {
            $.spinner().start();

            var addToCartUrl = addToCartObject.url;
            var pid = addToCartObject.pid;
            var priceInfo = addToCartObject.priceInfo;
            var productName = addToCartObject.productName;
            var channels = addToCartObject.channels;
            var operatorProductId = addToCartObject.operatorProductId;
            var price = getPrice(addToCartObject.price);
            var listPrice = getListPrice(addToCartObject.price);
            var shopName = addToCartObject.shopName;
            var offerState = addToCartObject.offerState;
            var offerStateCode = addToCartObject.offerStateCode;
            var shopId = addToCartObject.shopId;
            var quantity = $(this)
                .closest('.offer-details')
                .find('.offer-quantity-select')
                .val();
            var form = {
                pid: pid,
                price: price,
                channels: channels,
                listPrice: listPrice,
                productName: productName,
                quantity: quantity,
                operatorProductId: operatorProductId,
                shopName: shopName,
                offerState: offerState,
                offerStateCode: offerStateCode,
                shopId: shopId,
                priceInfo: priceInfo
            };

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }
    });
}

module.exports = {
    addOfferToCart: addOfferToCart
};
